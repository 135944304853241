<template>
  <div class="layout_common all_aging">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <el-date-picker
              v-model="day"
              type="week"
              format="yyyy 第 WW 周"
              size="small"
              placeholder="选择周"
              :picker-options="{ firstDayOfWeek: 1 }"
              @change="dayChange"
              :clearable="false"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="echart" v-loading="loading">
          <barEchart id1="aging_box" id2="aging_echart" :propData="echartData" :barColor="['#91cc75','#5470c6','#73c0de','#fac858']" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import barEchart from '../components/agingBar'
import { aging } from '@/api'
import { formatDate } from '@/utils/common'
export default {
  components: {
    barEchart
  },
  data () {
    return {
      loading: false,
      day: new Date(),
      echartData: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      aging({ time: formatDate(this.day) }).then(res => {
        this.loading = false
        this.handleEchartData(res.data)
      })
    },
    // 根据后台返回的数据进行处理成图表的格式的数据
    handleEchartData (data) {
      const dataX = data.map(item => item.createTime)
      const responseIn = data.map(item => item.rescription.responseIn)
      const responseOut = data.map(item => item.rescription.responseOut)
      const handleIn = data.map(item => item.rescription.handleIn)
      const handleOut = data.map(item => item.rescription.handleOut)
      const arr = [
        { name: '响应<= 30分钟', value: responseIn, dataX },
        { name: '响应 > 30分钟', value: responseOut, dataX },
        { name: '处理<= 4小时', value: handleIn, dataX },
        { name: '处理 > 4小时', value: handleOut, dataX }
      ]
      this.echartData = arr
    },
    dayChange (val) {
      this.day = val
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.all_aging {
  .echart {
    margin-top: 20px;
    height: calc(100% - 80px);
  }
}
</style>
